  import React, { useRef, useEffect } from 'react';
  import { graphql } from 'gatsby';
  import { StaticImage } from 'gatsby-plugin-image';
  import PropTypes from 'prop-types';
  import { Helmet } from 'react-helmet';
  import styled from 'styled-components';
  import { srConfig } from '@config';
  import sr from '@utils/sr';
  import { Layout } from '@components';
  import { usePrefersReducedMotion } from '@hooks';

  const StyledTableContainer = styled.div`
    margin: 100px -20px;

    @media (max-width: 768px) {
      margin: 50px -10px;
    }

    table {
      font-size: 25px;
      width: 100%;
      border-collapse: collapse;

      .hide-on-mobile {
        @media (max-width: 768px) {
          display: none;
        }
      }

      tbody tr {
        &:hover,
        &:focus {
          background-color: var(--light-navy);
        }
      }

      tbody tr th {
        font-weight: normal;
      }

      th,
      td {
        padding: 10px;
        text-align: left;

        &:first-child {
          padding-left: 20px;

          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }
        &:last-child {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      tr {
        
        cursor: default;

        td:first-child {
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        td:last-child {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }

      td {
        &.year {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
            font-size: var(--fz-sm);
          }
        }

        &.title {
          padding-top: 15px;
          padding-right: 20px;
          color: var(--lightest-slate);
          font-size: var(--fz-xl);
          font-weight: 600;
          line-height: 1.25;
        }

        &.company {
          font-size: var(--fz-lg);
          white-space: nowrap;
        }

        &.tech {
          font-size: var(--fz-xxs);
          font-family: var(--font-mono);
          line-height: 1.5;
          .separator {
            margin: 0 5px;
          }
          span {
            display: inline-block;
          }
        }

        &.links {
          min-width: 100px;

          div {
            display: flex;
            align-items: center;

            a {
              ${({ theme }) => theme.mixins.flexCenter};
              flex-shrink: 0;
            }

            a + a {
              margin-left: 10px;
            }
          }
        }
      }
    }
  `;

  const StyledAboutSection = styled.section`

  counter-reset: section;

  max-width: 1400px;

  h2 b {
    color: #547162;
  }

  h2, h3 {
    color: #768D81;
  }

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .numbered-heading {
    color: #547162;
    font-size: 42px;
    font-weight: bold;

    &:before {
      color: #547162;
      counter-increment: section 1;
      content: '0' counter(section) '.';
      font-size: clamp(var(--fz-md), 3vw, 24px);
      font-weight: bold;
    }

    &:after {
      background-color: #A9B7B0;
      width: 400px;
    }
  }

  
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  width: 700px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }
    }


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid #768D81;
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

  const ArchivePage = ({ location, data }) => {
    const projects = data.allMarkdownRemark.edges;
    const revealTitle = useRef(null);
    const revealTable = useRef(null);
    const revealProjects = useRef([]);
    const prefersReducedMotion = usePrefersReducedMotion();

    useEffect(() => {
      if (prefersReducedMotion) {
        return;
      }

      sr.reveal(revealTitle.current, srConfig());
      sr.reveal(revealTable.current, srConfig(200, 0));
      revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
    }, []);

    return (
      <Layout location={location}>
        <Helmet title="Archive" />

        <main>
          <header ref={revealTitle}>
            <h1 className="big-heading" style={{color: "#547162"}}>Sanctify</h1>
            <p className="subtitle" style={{fontSize:"22px", color: "#547162"}}> &#91; 2023 iF Design Award - Finalist &#93;<br/>
            Redesign the Onboarding Process of a Catholic Investment Mobile App.
            </p>
          </header>

          <StyledTableContainer ref={revealTable}>
            <table style={{color: "#547162"}}>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Team Members</th>
                  <th className="hide-on-mobile">My Role</th>
                  <th className="hide-on-mobile">Duration</th>
                  <th>Project Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2022</th>
                  <th>Yifan Wang, Nuran Chen, <br/> Xianli Zeng, Hang Wang</th>
                  <th> User Research, <br/> UI/UX Design</th>
                  <th>9 Weeks</th>
                  <th>Internship project <br/> collaborated with IWP Capital LLC</th>
                </tr>
              </tbody>
            </table>
          </StyledTableContainer>

          <StyledAboutSection id="about" ref={revealTable}>
        <h2 className="numbered-heading">Overview</h2>
        
        <div className="inner">
          <StyledText>
            <div>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>About Sanctify</b></h2>

            


              <h2>
              Sanctify is an all-in-one personalized screening investment app from IWP Capital Inc that upholds Catholic values.
              <br/><br/>
              It functions as a stepping stone for different types of investors wanting to uphold Catholic values and drive business growth at the same time.
              </h2>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>Our Challenge</b></h2>

              <h2>
              How might we build an onboarding system that guides users through more steps and encourage them to complete the onboarding process in a simple and attractive way?
              </h2>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>Our Goal</b></h2>

              <h2>
              Design an encouraging, attractive, and organized new onboarding system for users.
              </h2>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/overview.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/>

        {/* Section 02 - Initial Research */}

        <h2 className="numbered-heading" >Overview of Design Process</h2>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

        <StyledPic style={{width: "1200px"}}>
              <StaticImage
                className="img"
                src="../images/Sanctify/design.png"
                width={1200}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
          </StyledPic>

        {/* <StaticImage 
                className="img"
                src="../images/Sanctify/design.png"
                width={1500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              /> */}

        {/* <StyledText>
            <br/><br/>
            <div>
              <h2 style={{fontSize: "33px"}}><b>Research 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                → 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Ideation
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                → 
                </b></h2>
              <h2>Competitor Analysis 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                Define User Needs
              
                <br/> 
                Persona
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                Brainstorming
                </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "550px"}}>
            <br/><br/>
            <div>
              <h2 style={{fontSize: "33px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>Design 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                → 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Reflection</b></h2>
              <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Wireframe
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                Takeaways
              
                <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Hi-Fi Design
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Next Step

                <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Visual Illustration
                </h2>
                


            </div>
          </StyledText> */}

        </div>

        <br/><br/><br/>

        {/* Section 03 - Primary Research */}

        <h2 className="numbered-heading" >Research</h2>

        <div className="inner">
          <StyledText>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Competitor Analysis</b></h2>

              <br/>

              <h2>
              We conducted varies competitive fintech products in the market, and went through the user flow of each product and listed out their strength and weakness.
              <br/><br/>
              - Stash
              <br/>
              - Robinhood
              <br/>
              - Wealthfront
              <br/>
              - Betterment
              <br/>
              - Acrons
              <br/>
              - Ellevest
              </h2>

            </div>

          </StyledText>

          <StyledPic style={{width: "800px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/ca.jpg"
                width={800}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/>

        <div className="inner">

        <StyledPic style={{width: "850px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/ca-userflow.jpg"
                width={850}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledText>
            <div>

            <br/><br/><br/><br/><br/><br/>

              <h2 style={{fontSize: "33px"}}><b>Research of Competitor’s User Flow</b></h2>

            </div>

          </StyledText>
        </div>

        <br/><br/><br/><br/>

        <div style={{textAlign: "center"}}>

        <h2 style={{fontSize: "40px"}}><b>INSIGHTS</b></h2>

        <br/>

        <h2><b>We summarized the common design ideas from the competitor analysis: </b></h2>

        <br/>       
        <h2>
            ‣ Use the progress tracker to make users stick around to finish the process. <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ‣ Advertising page at beginning where company can introduce the products shortly. <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ‣ Present attractive illustration to visually interpret and encourage the process. <br/><br/>

            ‣ Tasks divided in reasonable sections to make users easier to follow. 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </h2>  
        
        </div>

        <br/><br/><br/><br/>

        <div className="inner">
          <StyledText>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>User Interview/Persona Overview</b></h2>

              <br/>

              <h3>‣ Most people interested in our product were Black/African-American men aged 30-40 with a yearly salary of $80,000 - $200,000+. Most were married with children and are in management positions.</h3>
              <br/>
              <h3>‣ The audience wants encouragement and motivation through the onboarding process. </h3>
              <br/>
              <h3>‣ Most of the people we interviewed do a lot of their own research and follow investment coaches/professionals on Instagram, Twitter, and YouTube.</h3>

            </div>

          </StyledText>

          <StyledPic >
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/persona.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - Ideate */}

        <h2 className="numbered-heading" >Ideation</h2>

        <h2 style={{fontSize: "38px", textAlign: "center"}}><b>Define User & Client Needs</b></h2>

        <br/>

        <div style={{textAlign: "center"}} className="inner">

        <StyledText>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>User Needs</b></h2>

              <br/>
              
              <h2 style={{textAlign: "left"}}>⦿ Use email address and password <br/> &nbsp;&nbsp;&nbsp;&nbsp; to sign up and log in into the account quickly </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Get an appealing overview of the product </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Have a meaningful and interesting <br/> &nbsp;&nbsp;&nbsp;&nbsp; onboarding experience </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "600px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Client Needs</b></h2>

              <br/>

              <h2 style={{textAlign: "left"}}>⦿ Fill up extra information on personal financial <br/> &nbsp;&nbsp;&nbsp;&nbsp; conditions for later personalized investment <br/> &nbsp;&nbsp;&nbsp;&nbsp; suggestions </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Introduce the product in an effective and <br/> &nbsp;&nbsp;&nbsp;&nbsp; attracting way </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Let users fill in more information and allow them <br/> &nbsp;&nbsp;&nbsp;&nbsp; to continue edit if they quit </h2>

            </div>
          </StyledText>

        </div>

        <br/><br/><br/>

        <h2 style={{fontSize: "38px", textAlign: "center"}}><b>User Flow</b></h2>

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>
        <StyledPic style={{width: "1250px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/userflow.jpg"
                width={1250}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>
        <StyledPic style={{width: "1250px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/userflow2.png"
                width={1250}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>
        
        <br/>

        <br/><br/><br/><br/><br/>

        {/* Section 05 - Design */}

        <h2 className="numbered-heading" >The Design</h2>

        <br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Wireframe</b></h2>

        <br/><br/>


        <div className="inner" style={{justifyContent: "center", display: "flex"}}>
        <StyledPic style={{width: "1250px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/wireframe.png"
                width={1250}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Hi-Fi Prototype</b></h2>

        <br/><br/><br/>

        {/* Feature 1 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/Sanctify/f1.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Onboarding Introduction</b></h2>
                <br/>
                <h2> Quickly introduce the product in an attracting and effective way </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 2 */}

        <div className="inner">

            <StyledText>
              <br/><br/>
              <div>
              
                <h2 style={{fontSize: "33px"}}><b>Break Down the Process</b></h2>
                <br/>
                <h2> The registration process is divided into three stages with encouragement messages after completion. 
                     <br/>
                     Users can resume the registration at home page.  </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/Sanctify/f2.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/>

        {/* Feature 3 */}

        <div className="inner">

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/Sanctify/f3.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              <br/>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>Registration Page</b></h2>
                <br/>
                <h2> A smooth registration process with progress bar on the top. </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 4 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/>
              
              <div>
                <br/>
                <h2 style={{fontSize: "33px"}}><b>Company Questioners</b></h2>
                <br/>
                <h2> Reduce the information density enhancing readability via adding visual elements and rearranging overall layout </h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "900px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/Sanctify/f4.png"
                  width={900}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>  
        
        <br/><br/><br/><br/><br/>

        {/* Section 06 - Innovations and Backgrounds */}

        <h2 className="numbered-heading" >Visual Illustration</h2>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

        <StyledPic style={{width: "800px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/visual.png"
                width={800}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

          <StyledPic style={{width: "400px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/Sanctify/designsystem.png"
                width={400}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/><br/>

        <h2 className="numbered-heading" >Reflection</h2>

        <div>

        <h2 style={{fontSize: "33px"}}><b>Key Takeaways</b></h2>       
        <h2>‣ This is my first time implementing the design system. Our team work together to create and utilized the entire design systems.</h2>
        <h2>‣ For the personal information verification, we collaborated with the developer team and integrated third-party verification platform “Plaid” to ensure the safety of customers. Their suggestions also help us generate more feasible and reliable design result.</h2>
        <h2>‣ Communications with product manager and engineers help me better understand the balance between user needs and client needs, as well as balance between design and front-end implementation. </h2>
        <br/>

        <h2 style={{fontSize: "33px"}}><b>Next Steps</b></h2>
        <h2>‣ We are waiting for the client to provide user data from the app pre-launch, and make further improvement based on the feedback from users and clients. </h2>
        <h2>‣ Work with the screening feature design team to make smoother transition from the onboarding stage to other features in the app. </h2>
        
        </div>
        
      </StyledAboutSection>
        </main>
      </Layout>
    );
  };
  ArchivePage.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  export default ArchivePage;

  export const pageQuery = graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              tech
              github
              external
              company
            }
            html
          }
        }
      }
    }
  `;
